export const skillsNames = [
    "Python",
    "JavaScript",
    "Docker",
    "TypeScript",
    "HTML & CSS",
    "Redis",
    "Bootstrap",
    "React",
    "Angular",
    "Node.js",
    "MongoDB",
    "SQL",
    "PostgreSQL",
    "Postman",
    "Vue.js",
    "Express",
    "Oracle",
    "C#",
    "Websocket",
    "Java"

]